<template>
  <div data-component-name="AppDropdownList">
    <AppInput
      v-if="searchable"
      type="search"
      :placeholder="searchablePlaceholder"
      v-model.trim="searchQuery"
      ref="searchRef"
    />

    <ul class="options">
      <AppDropdownListItem
        v-for="(option, index) of foundOptions"
        v-html="optionAttribute ? option[optionAttribute] : option"
        :selected="optionAttribute
          ? modelValue?.[optionAttribute] === option[optionAttribute]
          : modelValue === option
        "
        :key="index"
        @click="modelValue = option"
      />
    </ul>
  </div>
</template>

<script setup lang="ts">
import AppInput from '~/components/redesign/AppInput.vue';
import AppDropdownListItem from './AppDropdownListItem.vue';
import type { AppDropdownListProps, ModelValue } from './types';

const modelValue = defineModel<ModelValue>('modelValue');

const props = withDefaults(
  defineProps<AppDropdownListProps>(),
  {
    searchable: false,
    searchablePlaceholder: 'Enter search query',
    searchAttributes: () => [],

    options: () => [],
  },
);

const searchQuery = ref('');
const searchRef = ref<undefined | { focus(): void }>();

onMounted(() => {
  if (searchRef.value && ('focus' in searchRef.value)) {
    searchRef.value.focus();
  }
});

const foundOptions = computed(() => {
  if (!searchQuery.value.length) {
    return props.options;
  }

  // TODO: double check. AI response
  return props.options.filter(option => {
    if (props.searchAttributes && props.searchAttributes.length > 0) {
      return props.searchAttributes.some(attr => {
        if (option.hasOwnProperty(attr)) {
          return String(option[attr])
            .toLowerCase()
            .includes(searchQuery.value.toLowerCase());
        }

        return false;
      });
    } else {
      return (option as string)
        .toLowerCase()
        .includes(searchQuery.value.toLowerCase());
    }
  });
});
</script>

<style scoped lang="scss">
@import "$/mixins/components";
@import "$/functions/token";
@import "$/variables/shadows";

[data-component-name="AppDropdownList"] {
  background-color: token('surf-cont-primary');
  box-shadow: map-get($shadows, 'Elevation 1');

  ul.options {
    max-height: 15rem;
    overflow-y: auto;
    @include scrollbar;
  }
}
</style>
