export default function emitNativeEvent(
  target: HTMLElement,
  eventName: string,
  eventOptions?: EventInit,
) {
  target.dispatchEvent(
    new CustomEvent(eventName, {
      ...{ bubbles: true, cancelable: true, composed: true },
      ...eventOptions,
    }),
  );
}
