import { ZERO_BOUNCE } from "~/constants/api-endpoints";

export type ZeroBounceStatus = 'invalid' | 'valid';

export interface ZeroBounceQuery {
  email: string;
  ip_address?: string;
};

interface ZeroBounceResponseSuccess {
  address: string;
  status: 'valid' | 'invalid';
  sub_status: string;
  free_email: boolean;
  did_you_mean: string | null;
  account: string;
  domain: string;
  domain_age_days: string;
  smtp_provider: string;
  mx_record: string;
  mx_found: string;
  firstname: string;
  lastname: string;
  gender: string;
  country: string;
  region: string;
  city: string;
  zipcode: string;
  processed_at: string;
}

interface ZeroBounceResponseError {
  error: string
}

export type ResponseZeroBounce = ZeroBounceResponseError | ZeroBounceResponseSuccess;

const VALID_STATUS = 'valid';
const ALLOWED_DOMAINS = [
  'guest-post-service.com',
  'thetopdevelopers.com',
  'cohesity.com',
  'unitrends.com',
  'acronis.com',
  'arcserve.com',
];

export async function useZeroBounce(email: string, ip_address: string | null): Promise<ZeroBounceStatus> {
  const emailDomain = email.split('@')[1];

  if (ALLOWED_DOMAINS.includes(emailDomain)) {
    return await new Promise<ZeroBounceStatus>(resolve => setTimeout(() => resolve(VALID_STATUS), 150));
  }

  const { public: { api_v2: baseURL } } = useRuntimeConfig();

  try {
    const response = await $fetch<ResponseZeroBounce>(
      ZERO_BOUNCE,
      {
        baseURL,
        method: 'GET',
        query: { 
          email,
          ip_address,
         },
      },
    );

    if ('error' in response) {
      return 'valid';
    } else {
      return response.status;
    }
  } catch(error) {
  
    return VALID_STATUS;
  }
}
