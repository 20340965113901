<template>
  <div
    data-component-name="AppRadio"
    @click="setModelValue(value)"
  >
    <input
      ref="inputRef"
      :name
      :value
      :checked="modelValue === value"
      type="radio"
      :id
      @change="changeHandler"
    >

    <div
      class="circles"
      @click="emitNativeInputEvent"
    />
  </div>
</template>

<script setup lang="ts">
// utils
import emitNativeEvent from '~/utils/emitNativeEvent';

const props = defineProps<{
  id: string
  name: string
  value: string
}>();

const inputRef = ref<HTMLInputElement>(null);

const modelValue = defineModel<string>('modelValue');
const setModelValue = (value: typeof modelValue.value) => {
  modelValue.value = value;
};

const changeHandler = ({ target }: Event) => {
  if (!target || !(target instanceof HTMLInputElement)) return;
  setModelValue(target.value);
};

const emitNativeInputEvent = () => {
  if (props.value === modelValue.value) return;
  if (!(inputRef.value instanceof HTMLInputElement)) return;

  emitNativeEvent(inputRef.value, 'input');
  emitNativeEvent(inputRef.value, 'change');
};
</script>

<style scoped lang="scss">
@import "$/mixins/size";
@import "$/mixins/flex";

@import "$/functions/token";

[data-component-name="AppRadio"] {
  @include fixed-size(1.25rem);
  @include flex-center-center;
  cursor: pointer;
  transition: background-color 0.15s ease;
  will-change: background-color;

  input[type="radio"] {
    display: none;

    &:checked ~ {
      .circles {
        &:before {
          border-color: token('accent-hover');
        }

        &:after {
          background-color: token('accent-hover');
        }
      }
    }
  }

  .circles {
    position: relative;
    @include fixed-size(0.75rem);

    &:before,
    &:after {
      content: '';
      display: block;

      position: absolute;
      top: 50%;
      left: 50%;

      transform: translate(-50%, -50%);
      border-radius: 100px;
    }

    &:before {
      width: 100%;
      height: 100%;

      border: 1px solid token('icon-primary');
      transition: border-color 0.15s ease;
      will-change: border-color;
    }

    &:after {
      width: 0;
      height: 0;
      @include fixed-size(0.5rem);
      background-color: transparent;
      transition: background-color 0.15s ease;
      will-change: background-color;
    }
  }

  &:hover {
    background-color: rgba(token('accent-pressed'), 16%);
  }

  &:active {
    background-color: rgba(token('accent-pressed'), 32%);
  }
}
</style>
