<template>
  <form
    data-component-name="GetWhitePaperLong"
    autocomplete="off"
    @input.passive="setFormFillingStart"
    @submit="submitHandler"
    data-id="getWhitePaperLong"
  >
    <AppInput
      v-model.trim="fullname"
      type="text"
      :label="$t('Full_Name')"
      :error="errors.fullname"
      name="fullname"
      required
    />

    <AppInput
      v-model="email"
      type="email"
      :label="$t('Corporate_Email')"
      :error="errors.email"
      name="email"
      required
    />

    <AppInput
      v-model="company"
      type="text"
      :label="$t('Company')"
      :error="errors.company"
      name="company"
      required
    />

    <AppDropdown
      v-model="country"
      :options="activeCountries"
      option-attribute="label"
      label="Country"
      :placeholder="$t('Select_your_country')"
      name="country"
      required
      searchable
      :searchable-placeholder="$t('Search_by_country_name')"
      :search-attributes="['label', 'isoCode']"
      :error="errors.country"
    />

    <AppDropdown
      v-if="isUSA || isCanada"
      v-model="state"
      :options="activeStates"
      option-attribute="label"
      label="State"
      name="state"
      :placeholder="$t('Select_your_state')"
      required
      searchable
      :searchable-placeholder="$t('Search_by_state_name')"
      :search-attributes="['label', 'isoCode']"
      :error="errors.state"
    />

    <div class="radio">
      <strong>{{ $t('Kindly_Specify_Role') }}*</strong>

      <ul>
        <li
          v-for="option of RADIO_OPTIONS"
          :key="option"
        >
          <AppRadio
            v-model="lead_type"
            :value="option"
            :id="`leadType-${option}`"
            name="role"
          />

          <label :for="`leadType-${option}`">
            {{ option.toLowerCase() }}
          </label>
        </li>
      </ul>

      <span v-if="errors.lead_type" class="error" name="error">
        {{ errors.lead_type }}
      </span>
    </div>

    <div class="terms">
      <GDPRForUSA v-if="isUSA" />

      <template v-else>
        <AppCheckbox id="terms" v-model="allowPolicy" name="allowPolicy"/>

        <p>
          <label for="terms" :class="{ error: errors.allowPolicy }">
            {{ $t('allow_policy') }}
          </label>

          {{ $t('according_terms') }}

          <LinkPrivacyNotice />
        </p>
      </template>
    </div>

    <Buttons.Regular
      fill="solid"
      accent="purple"
      :disabled="!meta.valid"
      data-id="submit-getWhitePaperLong"
    >
      {{ $t('Submit') }}
      <SvgArrowForward />
    </Buttons.Regular>
  </form>
</template>

<script setup lang="ts">
import { useI18n } from '#i18n';
import * as yup from 'yup';
import { useForm } from 'vee-validate';
import { toTypedSchema } from '@vee-validate/yup';
import { useCommonStore } from '~/stores/common';
import { storeToRefs } from 'pinia';
import { submittedCookieValue } from '~/services/FormFillComponent';
import { pushDataLayer } from '~/services/Analytics';
import { getOsanoConsent } from '~/services/OsanoConsentData';
import Cookie from '~/services/Cookie';

// components
import AppInput from '~/components/redesign/AppInput.vue';
import AppRadio from '~/components/redesign/AppRadio.vue';
import AppDropdown from '~/components/redesign/AppDropdown';
import Buttons from '~/components/redesign/Buttons';
import AppCheckbox from '~/components/redesign/AppCheckbox.vue';
import GDPRForUSA from '~/components/redesign/GDPRForUSA.vue';
import LinkPrivacyNotice from '~/components/redesign/LinkPrivacyNotice.vue';

// constants
import { REGEX } from '~/constants';
import { WHITE_PAPER } from '~/constants/api-endpoints';

// files
import countries from '~/data/countries.json';

// utils
import omit from 'lodash.omit';
import pick from 'lodash.pick';
import getSubmittedFullName from '~/utils/getSubmittedFullName';

// types
import type { Props } from './types';
import type { State, Countries, Country, CountryWithStates } from '~/types/country';

const props = withDefaults(defineProps<Props>(), { page: 'white_paper' });
const emit = defineEmits<{ loading: [value: boolean], submitted: [] }>();

const { t } = useI18n();

const { ipInfo } = storeToRefs(useCommonStore());
const RADIO_OPTIONS = ['CUSTOMER', 'PARTNER'] as const;

const validationSchema = toTypedSchema(yup.object({
  fullname: yup
    .string()
    .max(128, ({ max }) => t('Validations.max', { length: max }))
    .required(t('Validations.This_field_is_required'))
    .default(getSubmittedFullName()),

  email: yup
    .string()
    .max(128, ({ max }) => t('Validations.max', { length: max }))
    .required(t('Validations.This_field_is_required'))
    .matches(REGEX.EMAIL, t('Validations.Invalid_email_address'))
    .companyEmail()
    .email()
    .default(submittedCookieValue('email') || ''),

  company: yup
    .string()
    .max(128, ({ max }) => t('Validations.max', { length: max }))
    .required(t('Validations.This_field_is_required'))
    .default(submittedCookieValue('company') || ''),

  country: yup
    .object()
    .default({ active: true, label: 'United States', isoCode: 'US' })
    .required(t('Validations.This_field_is_required')),

  state: yup
    .object()
    .default({ active: true, label: 'Massachusetts', isoCode: 'MA' })
    .when('country', {
      is: (country?: Country) => ['CA', 'US'].includes(country?.isoCode || ''),
      then: schema => schema.required(t('Validations.This_field_is_required')),
      otherwise: schema => schema.notRequired(),
    }),

  lead_type: yup
    .string()
    .oneOf(RADIO_OPTIONS)
    .required(t('Validations.This_field_is_required'))
    .default('CUSTOMER'),

  allowPolicy: yup
    .boolean()
    .when('country', {
      is: (value?: Country) => value?.isoCode === 'US',
      then: schema => schema.notRequired(),
      otherwise: schema => schema
        .oneOf([true], t('Validations.This_field_is_required'))
        .required(t('Validations.This_field_is_required')),
    }),
}));

const {
  errors,
  defineField,
  handleSubmit,
  resetForm,
  meta,
  setFieldValue,
  resetField,
} = useForm({ validationSchema });

const [fullname] = defineField('fullname');
const [email] = defineField('email');
const [company] = defineField('company');
const [lead_type] = defineField('lead_type');
const [country] = defineField('country');
const [state] = defineField('state');
const [allowPolicy] = defineField('allowPolicy');

const activeCountries = computed(() => (countries as Countries).filter(country => country.active));

const isUSA = computed(() => country.value?.isoCode === 'US');
const isCanada = computed(() => country.value?.isoCode === 'CA');

const countryHasStates = computed(() => (country.value && ('states' in country.value)));
const activeStates = computed(() => countryHasStates.value
  ? (country.value as CountryWithStates).states.filter(state => state.active)
  : []
);

watch(ipInfo, info => {
  if (!info) return;

  const foundCountry = (countries as Countries).find(country => country.isoCode === info.iso_code);

  if (foundCountry) {
    setFieldValue('country', foundCountry);

    let foundState: State | undefined;

    if ('states' in foundCountry) {
      foundState = foundCountry.states.find(item => item.isoCode === info.state);
    }

    setFieldValue('state', foundState || null);
  }
}, { immediate: true });

watch(country, (newCountry, oldCountry) => {
  if (newCountry.label === oldCountry.label) return;
  resetField('state', { value: null });
});

const formFillingStart = ref<null | number>(null);
const resetFormFillingStart = () => {
  formFillingStart.value = null;
};
const setFormFillingStart = () => {
  if (formFillingStart.value !== null) return;
  formFillingStart.value = Date.now();
};
const getFormFillingSeconds = (): number | undefined => {
  if (formFillingStart.value === null) return;
  return (Date.now() - formFillingStart.value) / 1000;
};

const submitHandler = handleSubmit(values => {
  const [firstname, lastname] = values.fullname.split(' ');

  const requestBody = {
    ...omit(values, 'allowPolicy', 'fullname'),

    firstname,
    lastname: lastname || firstname,

    country: values.country.label,
    state: values.state?.label,

    start_time: formFillingStart.value?.toString(),
    of_form_duration: getFormFillingSeconds()?.toString(),

    cookie: Cookie.getCookieArray(),

    href: window.location.href,
    page: props.page,

    entry_page: Cookie.get('EntryPage') || '',
    referrer_page: Cookie.get('RefererPage') || '',

    ...(props.customCampaign && { custom_campaign: props.customCampaign }),

    consent: getOsanoConsent(),
  };

  resetFormFillingStart();
  emit('loading', true);

  const { public: { api_app: baseURL } } = useRuntimeConfig();

  $fetch(WHITE_PAPER, {
    method: 'POST',
    baseURL,
    body: requestBody,
  }).then(() => {
    const existingCookie = Cookie.get('submitted_params');
    const cookieData = existingCookie ? JSON.parse(existingCookie) : {};
    const fieldsToUpdate = pick(requestBody, ['firstname', 'lastname', 'email', 'company']);

    Cookie.set(
      'submitted_params',
      JSON.stringify({
        ...cookieData,
        ...fieldsToUpdate,
      }),
    );

    emit('submitted');
    resetForm();

    Cookie.clearAfterSubmit()

    pushDataLayer(values.email, 'whitepaper');
  }).catch(error => {
    debug('catch', error);
  }).finally(() => {
    emit('loading', false);
  });
});
</script>

<style scoped lang="scss">
@import "$/functions/token";
@import "$/mixins/typography";
@import "$/mixins/flex";
@import "$/mixins/media";
@import "$/variables/shadows";

[data-component-name="GetWhitePaperLong"] {
  padding-top: 0.5rem;

  @include flex-start-start;
  flex-direction: column;
  gap: 1.5rem;

  .radio {
    strong {
      @include subtitle-3;

      display: block;
      margin-bottom: 0.5rem;
    }

    ul {
      @include flex-start-start;
      gap: 0.94rem;

      li {
        @include flex-center-start;
        gap: 0.13rem;

        [data-component-name="AppRadio"] {}

        label {
          cursor: pointer;
          @include body-3;
          text-transform: capitalize;
        }
      }
    }

    span.error {
      @include caption;
      color: token('error');
      margin-top: 0.25rem;
      display: block;
    }
  }

  .terms {
    @include flex-start-start;
    gap: 0.25rem;

    [data-component-name="AppCheckbox"] {}

    p {
      @include caption;

      label {
        display: block;
        margin-bottom: 0.5rem;
        cursor: pointer;

        &.error {
          @include caption;
          color: token('error');
        }
      }

      a {
        color: token('link');
        text-decoration: underline;
      }
    }
  }

  [data-component-name="Buttons.Regular"] {
    align-self: flex-end;

    @include mobile {
      align-self: stretch;
    }
  }
}
</style>
