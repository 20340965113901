
export const getOsanoConsent = () => {
  if (import.meta.client && 'Osano' in window && window.Osano.cm?.getConsent) {
    try {
      return window.Osano.cm.getConsent();
    } catch (error) {
      console.error('Error getting Osano consent:', error);

      return null;
    }
  }

  return null;
};